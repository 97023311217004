import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubmitForm from "../components/submit/submitForm"

const SubmitPage = () => (
    <Layout>
        <SEO title="Submit" />
        {/* <a href="submit@psychoactiveWear.co">Submit Images</a> */}
        <SubmitForm />
    </Layout>
)

export default SubmitPage
