import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import './submitForm.css'

function SubmitForm() {
    // const [name, setName] = useState('');
    // const [status, setStatus] = useState('');
    // const [email, setEmail] = useState('');
    // const [message, setMessage] = useState('');
    // const [file, setFile] = useState({});

    // const onDrop = useCallback(acceptedFiles => {
    //     console.log(acceptedFiles)
    //     setFile(acceptedFiles[0])
    // }, [])
    // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    // const encode = (data) => {
    //     const formData = new FormData();
    //     Object.keys(data).forEach((k) => {
    //         formData.append(k, data[k])
    //     });
    //     return formData
    // }

    // const handleSubmit = e => {
    //     const data = { "form-name": "contact", name, email, message, file }

    //     fetch("/", {
    //         method: "POST",
    //         // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
    //         body: encode(data)
    //     })
    //         .then(() => setStatus("Form Submission Successful!!"))
    //         .catch(error => setStatus("Form Submission Failed!"));

    //     e.preventDefault();
    // };

    // const handleChange = e => {
    //     const { name, value } = e.target
    //     if (name === 'name') {
    //         return setName(value)
    //     }
    //     if (name === 'email') {
    //         return setEmail(value)
    //     }
    //     if (name === 'message') {
    //         return setMessage(value)
    //     }
    // }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            Debugging
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div className="submitForm--wrapper">
            <form name="submit" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />
                <div className="submitForm--container">
                    <div className="submitForm--item">
                        <label hidden>
                            Name
                        </label>
                        <input className="submitForm--input" placeholder="Name" type="text" name="name" />
                    </div>
                    <div className="submitForm--item">
                        <label hidden>
                            Email
                        </label>
                        <input className="submitForm--input" placeholder="Email" type="email" name="email" />
                    </div>
                    <div className="submitForm--item">
                        <label hidden>
                            Images
                        </label>
                        <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <aside>
                                <h4>Files</h4>
                                <ul>{files}</ul>
                            </aside>
                        </section>
                    </div>
                    <input type="submit" value="Submit" className="submitForm--submit-button" />
                    {/* <h3>{status}</h3> */}
                </div>
            </form>
        </div >
    );
}

export default SubmitForm;